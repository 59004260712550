import React from 'react';
import { Content } from 'antd/es/layout/layout';
import Header from '../components/Header';

const PageNotFound = () => {
    return (
        <Content>
            <Header />
            <h1>404 - Sivua ei löytynyt!</h1>
            <p>Anteeksi, etsimääsi sivua ei ole olemassa. </p>
        </Content>
    );
};

export default PageNotFound;
