import React from "react";
import { Breadcrumb } from "antd";
import { useLocation, Link } from "react-router-dom";

export const pathNames: { [key: string]: string } = {
    organizations: "Organisaatiot",
    clients: "Asiakkaat",
    teams: "Tiimit",
    users: "Käyttäjät",
    analytics: "Analytiikka",
    uiMessages: "UI-tiedotteet",
    settings: "Asetukset",
    billingPlans: "Hinnoittelumallit",
    new: "Luo uusi",
    edit: "Muokkaa",
    dashboard: "Yleisnäkymä",
    integrations: "Kytkennät",
    invoiceQuery: "Käsittelyhistoria",
    import: "Tuonti",
    confirm: "Vahvista",
};

export type SnippetResolver = (identifier: string) => React.ReactNode | string;

const Breadcrumbs = (props: { resolver?: SnippetResolver }) => {
    const location = useLocation();

    const pathSnippets = location.pathname.split("/").filter((i) => i);

    const breadcrumbItems = [
        { title: "Koti", href: "/" },
        ...pathSnippets.map((part, index) => {
            const title =
                pathNames[part] || (props.resolver && props.resolver(part));
            const path = `/${pathSnippets.slice(0, index + 1).join("/")}`;

            return { title, href: path };
        }),
    ].map((item, index) => ({
        title:
            index !== pathSnippets.length ? (
                <Link to={item.href}>{item.title}</Link>
            ) : (
                item.title
            ),
    }));

    return <Breadcrumb items={breadcrumbItems} />;
};

export default Breadcrumbs;
